html{
	scroll-behavior: smooth;
}

.faqs {
	width: 100%;
	max-width: 768px;
	margin: 0 auto;
	padding: 15px;
}

.faqs .faq {
	margin: 15px;
	padding: 15px;
	background-color: #FFF;
	border-radius: 8px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faqs .faq .faq-question {
	position: relative;
	font-size: 25px;
	padding-right: 80px;

	transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;

	background-image: url('./image2vector.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
	transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
	margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
	background-image: url('./no.svg');
	transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
	max-height: 1000px;
	opacity: 1;
}

.social-container {
	padding: 0px 0px;
		position:fixed;
		bottom:0;
		right:0;
	background-color: whitesmoke;
	border-radius: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}

a.social {
	margin: 0 1rem;
	transition: transform 250ms;
	display: inline-block;
}

a.social:hover {
	transform: translateY(-5px);
	color: #70a5e9;
}

a.discord:hover {
	transform: translateY(-5px);
	color: #8b9cdd;
}

a.twitter {
	color: #49a1eb;
	margin-top: 15px;
	padding: 5px;
	margin-bottom: 15px;
	background-color: white;
	border-radius: 30px;
}

a.discord {
	color: #7289d9;
	padding: 5px;
	background-color: white;
	border-radius: 30px;
}

.confirmation {
	color: white;
}

.socialtitle1 {
	color: white;
	padding: 10px;
	font-size: 30px;
}

.opensealink {
	text-align: center;
	color: white;
}

.timer-container{
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
	width: 50vw;
	height: 10.3vw;
}

.timer {
	display: flex;
	flex: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.timer div:first-child{
	text-align: center;
}

.timer div:first-child p{
	color: #f8f8f8;
}

.timer div:last-child{
	border: 2px solid #fff;
	border-radius: 20px;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	margin-top: 3em;
	padding: 3em;
	text-align: center;
}

section p:first-child, .time div:last-child span {
	font-size: 3em;
}

@media screen and (max-width: 480px) {
	.timer div:last-child{
		margin-left: 2em;
		margin-right: 2em;
		padding: 3m 1em;
	}
}